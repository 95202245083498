import { useApi } from '~/composables/useApi'

export default defineNuxtRouteMiddleware(async () => {
	const route = useRoute()

	/**
	 * Probably back button was pressed and the applicationId is not present in the route
	 */
	if (!route.params.applicationId) {
		return
	}

	const { userApplication } = await useApi(`/applications/${route.params.applicationId}/user-role`)

	if (userApplication.role !== UserApplicationRole.ADMIN && userApplication.role !== UserApplicationRole.SELLER) {
		return navigateTo('/404')
	}
})
